<template>
  <v-card eagle-form="meta-action" class="mb-6" elevation="0" v-if="formMetaAction">
    <v-card-actions class="mx-0 px-0">
      <component
        v-for="(config, metaActionKey) in formMetaAction"
        :key="metaActionKey"
        :config="config"
        :formKey="formKey"
        :is="getComponent(config)"
      ></component>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  props: {
    formKey: String,
  },
  methods: {
    getComponent(config) {
      if(config.component) return config.component
      return 'formMetaButton'
    },
  },
  computed: {
    formMetaAction() {
      return this.$store.getters[`form/${this.formKey}/metaAction`]
    },
  },
  components: {
    formMetaButton: () => import('@/components/form/formMetaAction/button.vue'),
  },
}
</script>

<style lang="sass" type="text/sass" scoped></style>
